<template>
  <div>
    <van-cell-group>
      <van-field
        type="textarea"
        v-model="remark"
        :label="$t('备注')"
        :placeholder="$t('请输入备注')"
        maxlength="15"
        autosize
      />
    </van-cell-group>
    <div class="saveButton">
      <van-button
        round
        block
        @click="doSubmit"
        type="primary"
        :loading="submitLoading"
        :disabled="submitLoading"
      >
        {{ $t("确定") }}
      </van-button>
    </div>
  </div>
</template>

<script>
import { Field, Cell, CellGroup, Toast, Button } from "vant";
import { setRemark } from "@/service/api";

export default {
  components: {
    [Field.name]: Field,
    [Toast.name]: Toast,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
  },
  data() {
    return {
      submitLoading: false,
      id: this.$route.query.id,
      remark: this.$route.query.remark,
    };
  },
  methods: {
    async doSubmit() {
      const remark = this.remark;
      if (!remark || remark.length === 0) {
        Toast(this.$t("请输入备注"));
        return;
      }

      if (remark.length > 15) {
        Toast(this.$t("备注最多不超过15个字符"));
        return;
      }

      const params = {
        id: this.id,
        remark: this.remark,
      };

      this.submitLoading = true;
      const res = await setRemark(params);
      this.submitLoading = false;

      if (res.success) {
        Toast.success(this.$t("编辑成功"));
        this.$router.go(-1);
      } else Toast.fail(res.message);
    },
  },
};
</script>
<style lang="less" scoped>
.saveButton {
  margin: 30px 30px 0 30px;
}
</style>